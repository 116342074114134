import { useMemo } from 'react';
import { useAppSelector } from '../hooks';
import { GlobalState } from './reducer';

export function useGlobalState() {
  return useAppSelector((state) => state.global as GlobalState);
}

// 用户列表
export function useUserTypes() {
  const globalState = useGlobalState();
  return useMemo(() => globalState.userTypeList, [globalState.userTypeList]);
}

// 角色列表
export function useUserRoles() {
  const globalState = useGlobalState();
  return useMemo(() => globalState.userRoleList, [globalState.userRoleList]);
}

// 部门列表
export function useDeparments() {
  const globalState = useGlobalState();
  return useMemo(() => globalState.userDepartmentList, [globalState.userDepartmentList]);
}

// site列表
export function useSites() {
  const globalState = useGlobalState();
  return useMemo(() => globalState.siteList ?? [], [globalState.siteList]);
}

// 是否正在加载site列表
export function useLoadingSites() {
  const globalState = useGlobalState();
  return useMemo(() => globalState.loadingSites, [globalState.loadingSites]);
}

// area列表
export function useAreas() {
  const globalState = useGlobalState();
  return useMemo(() => globalState.areaList ?? [], [globalState.areaList]);
}

// 是否正在加载area列表
export function useLoadingAreas() {
  const globalState = useGlobalState();
  return useMemo(() => globalState.loadingAreas, [globalState.loadingAreas]);
}

// zone列表
export function useZones() {
  const globalState = useGlobalState();
  return useMemo(() => globalState.zoneList ?? [], [globalState.zoneList]);
}

// 是否正在加载zone列表
export function useLoadingZones() {
  const globalState = useGlobalState();
  return useMemo(() => globalState.loadingZones, [globalState.loadingZones]);
}

export function useDeviceGroupRoles() {
  const globalState = useGlobalState();
  return useMemo(() => globalState.deviceGroupRoles ?? [], [globalState.deviceGroupRoles]);
}
