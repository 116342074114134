// ----------------------------------------------------------------------

import { useState, useCallback, useLayoutEffect } from 'react';
import { useGetAreaListCallback } from 'src/api/area';
import { useGetSiteZoneCallback } from 'src/api/dashboard';
import { useGetSiteListCallback } from 'src/api/site';
import { useGetZoneListCallback } from 'src/api/zone';
import { useSnackbar } from 'src/components/snackbar';
import { useSites, useAreas, useZones } from 'src/stores/global/hooks';
import { useAppDispatch } from 'src/stores/hooks';
import { IAreaItem } from 'src/types/area';
import { ISiteItem } from 'src/types/site';
import { IZoneItem } from 'src/types/zone';

/**
 * useLocationModuleLocal 创建模块内自用
 * 的 sites / areas / zones
 */
export function useLocationModuleLocal() {
  const { enqueueSnackbar } = useSnackbar();
  const getSiteList = useGetSiteListCallback();
  const getSiteArea = useGetAreaListCallback();
  const getSiteZone = useGetZoneListCallback();

  const defaultSites = useSites();
  const defaultAreas = useAreas();
  const defaultZones = useZones();
  const [sites, setSites] = useState<ISiteItem[]>(defaultSites);
  const [areas, setAreas] = useState<IAreaItem[]>(defaultAreas);
  const [zones, setZones] = useState<IZoneItem[]>(defaultZones);

  const [loadingSites, setLoadingSites] = useState(false);
  const [loadingAreas, setLoadingAreas] = useState(false);
  const [loadingZones, setLoadingZones] = useState(false);

  const refreshSites = useCallback(() => {
    if (!loadingSites)
      getSiteList({})
        .then((res) => {
          setSites(res.data.rows);
        })
        .catch((err) => {
          enqueueSnackbar(err, { variant: 'error' });
        })
        .finally(() => {
          setLoadingSites(false);
        });
  }, [enqueueSnackbar, getSiteList, loadingSites]);

  useLayoutEffect(() => {
    if (!sites.length) refreshSites();
  }, [refreshSites, sites.length]);

  type refreshAreasProps = { site_id?: string; site_ids?: string[] };

  const refreshAreas = useCallback(
    (props?: refreshAreasProps) => {
      if (!sites.length || loadingAreas) return;
      const site_ids = props?.site_ids ?? sites.map((site) => site.site_id) ?? [];
      if (site_ids.length === 0) return;
      getSiteArea({ site_id: props?.site_id, site_ids })
        .then((res) => {
          setAreas(res.data.rows);
        })
        .catch((err) => {
          enqueueSnackbar(err, { variant: 'error' });
        })
        .finally(() => {
          setLoadingAreas(false);
        });
    },
    [loadingAreas, sites, getSiteArea, enqueueSnackbar]
  );

  useLayoutEffect(() => {
    if (!areas.length) refreshAreas();
  }, [areas.length, refreshAreas]);

  type refreshZonesProps = { site_id?: string; site_area_ids?: string[] };

  const refreshZones = useCallback(
    (props?: refreshZonesProps) => {
      if (!loadingZones) {
        const site_area_ids = props?.site_area_ids ?? areas.map((area) => area.area_id) ?? [];
        if (site_area_ids.length === 0) return;
        getSiteZone({ site_id: props?.site_id, site_area_ids })
          .then((res) => {
            setZones(res.data.rows);
          })
          .catch((err) => {
            enqueueSnackbar(err, { variant: 'error' });
          })
          .finally(() => {
            setLoadingZones(false);
          });
      }
    },
    [loadingZones, areas, getSiteZone, enqueueSnackbar]
  );

  useLayoutEffect(() => {
    if (!zones.length) refreshZones();
  }, [refreshZones, zones.length]);

  return {
    sites,
    areas,
    zones,
    refreshSites,
    refreshAreas,
    refreshZones,
  };
}
