import { useCallback } from 'react';
// utils
import { AxiosResponse } from 'axios';
import _axios, { _endpoints } from 'src/utils/_axios';
// types
import { useAuthContext } from 'src/auth/hooks';
import { IAreaItem } from 'src/types/area';

// ----------------------------------------------------------------------

/**
 * 获取 Client 数据
 */
export const useGetClientInfoCallback = () => {
  const auth = useAuthContext();

  return useCallback(
    ({
      client_id,
      input_now,
      page_no, // utc,
    }: {
      client_id?: string;
      input_now?: number;
      page_no?: number;
      // utc: string;
    }) =>
      new Promise<AxiosResponse>((resolve, reject) => {
        if (!auth.user) {
          reject(new Error('User is not logged in'));
          return;
        }

        const params = {
          email: auth.user.email as string,
          client_id: client_id ?? (auth.user.client_id as string),
          utc: auth.user.user_utc,
          input_now,
          page_no,
        };

        _axios
          .get(_endpoints.client.get, {
            params,
          })
          .then((res) => {
            if (res.data.code !== '1') {
              reject(new Error(res.data.message || 'Unknown error'));
              return;
            }

            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      }),
    [auth.user]
  );
};
