import { lazy, Suspense } from 'react';

// ----------------------------------------------------------------------

// OVERVIEW
/**
 * lazy: lazy load components
 */
const ServerPage = lazy(() => import('src/pages/configuration/server'));

// ----------------------------------------------------------------------

export const configurationRoutes = [{ path: 'server', element: <ServerPage />, index: true }];
