import { lazy, Suspense } from 'react';

// ----------------------------------------------------------------------

// OVERVIEW
/**
 * lazy: lazy load components
 */

// USER
const UserListPage = lazy(() => import('src/pages/management/user/list'));
// DEVICE
const DevicePage = lazy(() => import('src/pages/management/device'));

// ----------------------------------------------------------------------

export const managementRoutes = [
  {
    path: 'user',
    children: [
      { element: <UserListPage />, index: true },
      { path: 'list', element: <UserListPage /> },
    ],
  },
  { path: 'device', element: <DevicePage />, index: true },
];
