import { lazy, Suspense } from 'react';

// ----------------------------------------------------------------------

// OVERVIEW
/**
 * lazy: lazy load components
 */

const SitePage = lazy(() => import('src/pages/location/site'));
const AreaPage = lazy(() => import('src/pages/location/area'));
const ZonePage = lazy(() => import('src/pages/location/zone'));

// ----------------------------------------------------------------------

export const locationRoutes = [
  { path: 'site', element: <SitePage />, index: true },
  { path: 'area', element: <AreaPage />, index: true },
  { path: 'zone', element: <ZonePage />, index: true },
];
