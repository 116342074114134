import { createSlice } from '@reduxjs/toolkit';
import { LOGIN_RESP_INFO } from 'src/auth/types';
import { updateVersion } from '../actions';

export type UserState = {
  user: LOGIN_RESP_INFO | null;
  loading: boolean;
  clientId: string;
};

const initialState: UserState = {
  user: null,
  loading: false,
  clientId: '',
};
const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // 登录成功
    login(state, action: { payload: LOGIN_RESP_INFO }) {
      const { payload } = action;
      state.user = payload;
    },
    // 验证登录情况，传 null 相当于退出登录
    verification(state, action: { payload: LOGIN_RESP_INFO | null }) {
      const { payload } = action;
      state.user = payload;
    },
    // 退出登录
    logout(state) {
      state.user = null;
    },
    // 注册
    register(state, action: { payload: LOGIN_RESP_INFO }) {
      const { payload } = action;
      state.user = payload;
    },
    changeAccount(state, action: { payload: any }) {
      // TODO: 切换账号
    },
    // 设置 loading 状态
    loading(state, action: { payload: boolean }) {
      const { payload } = action;
      state.loading = payload;
    },
    // 设置 clientId
    setClientId(state, action: { payload: string }) {
      const { payload } = action;
      state.clientId = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateVersion, (state) => {
      // 数据结构如果发生了变更，该怎么处理
      state = initialState;
    });
  },
});

export const userActions = slice.actions;
export default slice.reducer;
