import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { updateVersion } from './actions';
import global from './global/reducer';
import user from './user/reducer';

const reducers = combineReducers({
  global,
  user,
});

const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: true,
      serializableCheck: false,
    }),
});

store.dispatch(updateVersion());

export default store;

export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
