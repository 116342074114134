import { createAction } from '@reduxjs/toolkit';
// import { CurrentAccount } from "@state/account/reducer"

// fired once when the app reloads but before the app renders
// allows any updates to be applied to store data loaded from localStorage
export const updateVersion = createAction<void>('global/updateVersion');
// export const deleteContact = createAction<{ username; mx_user_id }>("global/deleteContact")
// export const resolveLogin = createAction<CurrentAccount>("global/resolveLogin")
// export const rejectLogin = createAction<void>("global/rejectLogin")
// export const globalPadding = createAction<void>("global/globalPadding")
