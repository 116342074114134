import { createSlice } from '@reduxjs/toolkit';
import { IUserDepartmentsItem, IUserRoleItem, IUserTypeItem } from 'src/types/user';
import { ISiteItem } from 'src/types/site';
import { IAreaItem } from 'src/types/area';
import { IZoneItem } from 'src/types/zone';
import { IGroupRoleItem } from 'src/types/group';
import { updateVersion } from '../actions';

export type GlobalState = {
  userTypeList: IUserTypeItem[];
  userRoleList: IUserRoleItem[];
  userDepartmentList: IUserDepartmentsItem[];
  siteList: ISiteItem[];
  loadingSites: boolean;
  areaList: IAreaItem[];
  loadingAreas: boolean;
  zoneList: IZoneItem[];
  loadingZones: boolean;
  deviceGroupRoles: IGroupRoleItem[];
};

const initialState: GlobalState = {
  userTypeList: [],
  userRoleList: [],
  userDepartmentList: [],
  siteList: [],
  loadingSites: false,
  areaList: [],
  loadingAreas: false,
  zoneList: [],
  loadingZones: false,
  deviceGroupRoles: [],
};
const slice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    changeAccount(state, action: { payload: any }) {
      // TODO: 切换账号
    },
    setUserTypeList(
      state,
      action: {
        payload: {
          datas: IUserTypeItem[];
        };
      }
    ) {
      const {
        payload: { datas },
      } = action;
      state.userTypeList = datas;
    },
    setUserRoleList(
      state,
      action: {
        payload: {
          datas: IUserRoleItem[];
        };
      }
    ) {
      const {
        payload: { datas },
      } = action;
      state.userRoleList = datas;
    },
    setUserDepartmentList(
      state,
      action: {
        payload: {
          datas: IUserDepartmentsItem[];
        };
      }
    ) {
      const {
        payload: { datas },
      } = action;
      state.userDepartmentList = datas;
    },
    setSiteList(
      state,
      action: {
        payload: {
          datas: ISiteItem[];
        };
      }
    ) {
      const {
        payload: { datas },
      } = action;
      state.siteList = datas;
    },
    setLoadingSites(state, action: { payload: boolean }) {
      const { payload } = action;
      state.loadingSites = payload;
    },
    setAreaList(
      state,
      action: {
        payload: {
          datas: IAreaItem[];
        };
      }
    ) {
      const {
        payload: { datas },
      } = action;
      state.areaList = datas;
    },
    setLoadingAreas(state, action: { payload: boolean }) {
      const { payload } = action;
      state.loadingAreas = payload;
    },
    setZoneList(
      state,
      action: {
        payload: {
          datas: IZoneItem[];
        };
      }
    ) {
      const {
        payload: { datas },
      } = action;
      state.zoneList = datas;
    },
    setLoadingZones(state, action: { payload: boolean }) {
      const { payload } = action;
      state.loadingZones = payload;
    },
    // 设备分组角色列表
    setDeviceGroupRoles(
      state,
      action: {
        payload: {
          datas: IGroupRoleItem[];
        };
      }
    ) {
      const {
        payload: { datas },
      } = action;
      state.deviceGroupRoles = datas;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateVersion, (state) => {
      // 数据结构如果发生了变更，该怎么处理
      state = initialState;
    });
    // .addCase(resolveLogin, (state) => {
    //   state.isReady = true
    // })
    // .addCase(rejectLogin, (state) => {
    //   state.isReady = true
    // })
    // .addCase(globalPadding, (state) => {
    //   state.isReady = false
    // })
  },
});

export const globalActions = slice.actions;
export default slice.reducer;
