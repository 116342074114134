import { useEffect, useReducer, useCallback, useMemo } from 'react';
// utils
import { type AxiosResponse } from 'axios';
import { useSelector } from 'react-redux';
import { fi } from 'date-fns/locale';
import axios, { endpoints } from 'src/utils/axios';
//
import _axios, { _endpoints, RESPONSE } from 'src/utils/_axios';
import { useLocales } from 'src/locales';
import { STORAGE_REFRESH_TOKEN, STORAGE_TOKEN } from 'src/constants';
import { useAppDispatch } from 'src/stores/hooks';
import { userActions } from 'src/stores/user/reducer';
import { useUserState } from 'src/stores/user/hooks';
import { AuthContext } from './auth-context';
import { setSession } from './utils';
import { LOGIN_RESP_INFO } from '../../types';

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

// enum Types {
//   INITIAL = 'INITIAL',
//   LOGIN = 'LOGIN',
//   REGISTER = 'REGISTER',
//   LOGOUT = 'LOGOUT',
// }

// type Payload = {
//   [Types.INITIAL]: {
//     user: AuthUserType;
//   };
//   [Types.LOGIN]: {
//     user: AuthUserType; // LOGIN_RESP_INFO;
//   };
//   [Types.REGISTER]: {
//     user: AuthUserType;
//   };
//   [Types.LOGOUT]: undefined;
// };

// type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

// ----------------------------------------------------------------------

// const initialState: AuthStateType = {
//   user: null,
//   loading: true,
// };

// const reducer = (state: AuthStateType, action: ActionsType) => {
//   if (action.type === Types.INITIAL) {
//     return {
//       loading: false,
//       user: action.payload.user,
//     };
//   }
//   if (action.type === Types.LOGIN) {
//     return {
//       ...state,
//       user: action.payload.user,
//     };
//   }
//   if (action.type === Types.REGISTER) {
//     return {
//       ...state,
//       user: action.payload.user,
//     };
//   }
//   if (action.type === Types.LOGOUT) {
//     return {
//       ...state,
//       user: null,
//     };
//   }
//   return state;
// };

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export function AuthProvider({ children }: Props) {
  // const [state, dispatch] = useReducer(reducer, initialState);
  const dispatch = useAppDispatch();

  const { t } = useLocales();

  const initialize = useCallback(async () => {
    try {
      dispatch(userActions.loading(true));
      const refresh_token = sessionStorage.getItem(STORAGE_REFRESH_TOKEN);

      // if (accessToken && isValidToken(accessToken)) {
      if (refresh_token) {
        const res = await _axios.post(_endpoints.auth.regreshToken, {
          refresh_token,
        });
        const user: LOGIN_RESP_INFO = res.data.info;

        setSession(user.token ?? null, user.refresh_token ?? null);

        dispatch(userActions.verification(user));
      } else {
        dispatch(userActions.verification(null));
      }
    } catch (error) {
      console.error(error);
      dispatch(userActions.verification(null));
    } finally {
      dispatch(userActions.loading(false));
    }
  }, [dispatch]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(
    async (email: string, password: string) => {
      const data = {
        email,
        password,
      };

      // const res = await axios.post(endpoints.auth.login, data); // mock
      const res: AxiosResponse<LOGIN_RESP_INFO, any> = await _axios.post(
        _endpoints.auth.login,
        data
      );

      const info = res.data;

      if (!info.token) {
        // 登录失败
        throw Error(t('Login failed, unknown error'));
      }

      setSession(info.token, info.refresh_token);

      dispatch(userActions.login(info));
    },
    [dispatch, t]
  );

  // REGISTER
  const register = useCallback(
    async (email: string, password: string, firstName: string, lastName: string) => {
      const data = {
        email,
        password,
        firstName,
        lastName,
      };

      const res = await axios.post(endpoints.auth.register, data);

      const { accessToken, user } = res.data;

      sessionStorage.setItem(STORAGE_TOKEN, accessToken);

      dispatch(userActions.register(user));
    },
    [dispatch]
  );

  // LOGOUT
  const logout = useCallback(async () => {
    setSession(null, null);
    dispatch(userActions.logout());
  }, [dispatch]);

  // ----------------------------------------------------------------------

  const state = useUserState();
  const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      method: 'jwt',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      //
      login,
      register,
      logout,
    }),
    [login, logout, register, state.user, status]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}
