import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/_dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';
import { applicationRoutes } from './application';
import { managementRoutes } from './management';
import { configurationRoutes } from './configuration';
import { locationRoutes } from './location';

// ----------------------------------------------------------------------

export const _dashboardRoutes = [
  {
    element: (
      <AuthGuard>
        <DashboardLayout>
          {/* Supense: 过度器，未加载完成 Outlet 时候显示 LoadingScreen */}
          <Suspense fallback={<LoadingScreen />}>
            {/* Outlet: 路由子组件 */}
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      {
        path: 'application',
        children: [...applicationRoutes],
      },
      {
        path: 'management',
        children: [...managementRoutes],
      },
      {
        path: 'location',
        children: [...locationRoutes],
      },
      {
        path: 'configuration',
        children: [...configurationRoutes],
      },
    ],
  },
];
